import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

const EmpoweringGroupsPage = ({ data: { strapiEmpoweringGroupsPage } }) => (
  <Layout bodyTheme="theme-books">
    <SEO title="Empowering Groups" />
    <header>
      <Menu />
    </header>
    <main className="page-wrapper">
      <section className="section section-first pb-0">
        <nav className="mission-statement-nav">
          <div className="container">
            <ul>
              <li>
                <Link to="/mission-statement">Overview</Link>
              </li>
              <li>
                <Link to="/championing-individuals">
                  Championing Individuals
                </Link>
              </li>
              <li>
                <Link to="/empowering-groups" className="active">
                  Empowering Groups
                </Link>
              </li>
              <li>
                <Link to="/experience">Experience</Link>
              </li>
            </ul>
          </div>
        </nav>
      </section>
      <section className="section pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center mb-2">
                <div className="page-heading">
                  <h1>{strapiEmpoweringGroupsPage["Title"]}</h1>
                  <p>{strapiEmpoweringGroupsPage["Description"]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div class="mission-statement-sub-page-content">
            {strapiEmpoweringGroupsPage["Content"].map((section, idx) => {
              return (
                <div key={idx}>
                  {idx !== 0 ? <hr /> : null}
                  <h3>{section.title}</h3>
                  <ReactMarkdown source={section["content"]} />
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <Footer />
    </main>
  </Layout>
)

export const query = graphql`
  query EmpoweringGroupsPage {
    strapiEmpoweringGroupsPage {
      Title
      Description
      Content {
        content
        title
      }
    }
  }
`
export default EmpoweringGroupsPage
